@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --font-family: Poppins, sans-serif;
  --clr-background: #ffffff;
  --clr-background-hover: rgba(0, 0, 0, 0.1);
  --divider: 1px solid #cccccc;
  --clr-text: #404040;
  --clr-text-secondary: #ffffff;
  --clr-icon: #ffffff;
  --nav-background: #ffffff;
  --nav-box-shadow: 0 4px 30px #ccc;
  --dialog-background: #ffffff;
  --dialog-box-shadow: 0 4px 30px #ccc;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out, fill 0.3s ease-in-out, stroke 0.3s ease-in-out;
}

[data-theme=light] {
  --font-family: Poppins, sans-serif;
  --clr-background: #ffffff;
  --clr-background-hover: rgba(0, 0, 0, 0.1);
  --divider: 1px solid #cccccc;
  --clr-text: #404040;
  --clr-text-secondary: #ffffff;
  --clr-icon: #ffffff;
  --nav-background: #ffffff;
  --nav-box-shadow: 0 4px 30px #ccc;
  --dialog-background: #ffffff;
  --dialog-box-shadow: 0 4px 30px #ccc;
}

[data-theme=dark] {
  --font-family: Poppins, sans-serif;
  --clr-background: #212121;
  --clr-background-hover: rgba(255, 255, 255, 0.1);
  --divider: 1px solid #404040;
  --clr-text: #dadada;
  --clr-text-secondary: #ffffff;
  --clr-icon: #2360c8;
  --nav-background: #101010;
  --nav-box-shadow: 0 4px 30px #101010;
  --dialog-background: #101010;
  --dialog-box-shadow: 0 4px 30px #101010;
}

/* Light and dark mode */
/*@media (prefers-color-scheme: dark) {
    :root {
        --clr-background: #212121;
        --clr-background-hover: rgba(255, 255, 255, 0.1);

        --divider: 1px solid #404040;

        --clr-text: #dadada;
        --clr-text-secondary: #ffffff;
        --clr-icon: #2360c8;

        --nav-background: #101010;
        --nav-box-shadow: 0 4px 30px #101010;
    }
}*/
:root {
  --theme-switch-hue: 223;
  --theme-switch-primary: hsl(var(--theme-switch-hue), 90%, 55%);
  --theme-switch-primaryT: hsla(var(--theme-switch-hue), 90%, 55%, 0);
  --theme-switch-transDur: 0.3s;
}

input {
  margin: 0;
  user-select: none;
}

.theme-switch-wrapper {
  top: 0.5rem;
  right: 0.5rem;
  position: fixed;
  background: none;
  border: none;
  padding: 0;
  border-radius: 5rem;
}

.theme-switch-wrapper * {
  cursor: pointer;
}

/* Default */
.theme-switch {
  display: flex;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

.theme-switch-icon {
  transition: transform var(--theme-switch-transDur) ease-in-out;
}

.theme-switch-icon,
.theme-switch-icon-part {
  position: absolute;
}

.theme-switch-icon {
  display: block;
  top: 0.5em;
  left: 0.5em;
  width: 1.5em;
  height: 1.5em;
}

.theme-switch-icon-part {
  border-radius: 50%;
  box-shadow: 0.4em -0.4em 0 0.5em white inset;
  top: calc(50% - 0.5em);
  left: calc(50% - 0.5em);
  width: 1em;
  height: 1em;
  transition: box-shadow var(--theme-switch-transDur) ease-in-out, opacity var(--theme-switch-transDur) ease-in-out, transform var(--theme-switch-transDur) ease-in-out, position var(--theme-switch-transDur) ease-in-out;
  transform: scale(0.5);
}
.theme-switch-icon-part ~ .theme-switch-icon-part {
  background-color: white;
  border-radius: 0.05em;
  box-shadow: none;
  top: 50%;
  left: 50%;
  transform: rotate(0deg) translateY(0.5em);
  transform-origin: 50% 0;
  width: 0.1em;
  height: 0.2em;
}
.theme-switch-icon-part:nth-child(3) {
  transform: rotate(45deg) translateY(0.5em);
}
.theme-switch-icon-part:nth-child(4) {
  transform: rotate(90deg) translateY(0.5em);
}
.theme-switch-icon-part:nth-child(5) {
  transform: rotate(135deg) translateY(0.5em);
}
.theme-switch-icon-part:nth-child(6) {
  transform: rotate(180deg) translateY(0.5em);
}
.theme-switch-icon-part:nth-child(7) {
  transform: rotate(225deg) translateY(0.5em);
}
.theme-switch-icon-part:nth-child(8) {
  transform: rotate(270deg) translateY(0.5em);
}
.theme-switch-icon-part:nth-child(9) {
  transform: rotate(315deg) translateY(0.5em);
}

.theme-switch-icon-toggle,
.theme-switch-icon-toggle-wrap {
  position: relative;
}

.theme-switch-icon-toggle,
.theme-switch-icon-toggle:before {
  display: block;
}

.theme-switch-icon-toggle {
  background-color: #fbedb6;
  border-radius: 50%;
  box-shadow: 0 0 0 0.125em var(--theme-switch-primaryT);
  padding: 0.25em;
  width: 3em;
  height: 3em;
  -webkit-appearance: none;
  appearance: none;
  transition: background-color var(--theme-switch-transDur) ease-in-out, box-shadow 0.15s ease-in-out, transform var(--theme-switch-transDur) ease-in-out;
}
.theme-switch-icon-toggle:before {
  background-color: #f4ca25;
  border-radius: 50%;
  content: "";
  width: 2.5em;
  height: 2.5em;
  transition: background-color var(--theme-switch-transDur) ease-in-out, transform var(--theme-switch-transDur) ease-in-out;
}
.theme-switch-icon-toggle:focus {
  box-shadow: 0 0 0 0.125em var(--theme-switch-primary);
  outline: transparent;
}
.theme-switch-icon-toggle:checked {
  background-color: #043449;
}
.theme-switch-icon-toggle:checked:before {
  background-color: #25b6f4;
}
.theme-switch-icon-toggle:checked ~ .theme-switch-icon .theme-switch-icon-part ~ .theme-switch-icon-part {
  opacity: 0;
}
.theme-switch-icon-toggle:checked ~ .theme-switch-icon .theme-switch-icon-part:nth-child(1) {
  box-shadow: 0.2em -0.2em 0 0.2em white inset;
  transform: scale(1);
}
.theme-switch-icon-toggle:checked ~ .theme-switch-icon .theme-switch-icon-part:nth-child(2) {
  transform: rotate(45deg) translateY(0.8em);
}
.theme-switch-icon-toggle:checked ~ .theme-switch-icon .theme-switch-icon-part:nth-child(3) {
  transform: rotate(90deg) translateY(0.8em);
}
.theme-switch-icon-toggle:checked ~ .theme-switch-icon .theme-switch-icon-part:nth-child(4) {
  transform: rotate(135deg) translateY(0.8em);
}
.theme-switch-icon-toggle:checked ~ .theme-switch-icon .theme-switch-icon-part:nth-child(5) {
  transform: rotate(180deg) translateY(0.8em);
}
.theme-switch-icon-toggle:checked ~ .theme-switch-icon .theme-switch-icon-part:nth-child(6) {
  transform: rotate(225deg) translateY(0.8em);
}
.theme-switch-icon-toggle:checked ~ .theme-switch-icon .theme-switch-icon-part:nth-child(7) {
  transform: rotate(270deg) translateY(0.8em);
}
.theme-switch-icon-toggle:checked ~ .theme-switch-icon .theme-switch-icon-part:nth-child(8) {
  transform: rotate(315deg) translateY(0.8em);
}
.theme-switch-icon-toggle:checked ~ .theme-switch-icon .theme-switch-icon-part:nth-child(9) {
  transform: rotate(360deg) translateY(0.8em);
}

@supports selector(:focus-visible) {
  .theme-switch-icon-toggle:focus {
    box-shadow: 0 0 0 0.125em var(--theme-switch-primaryT);
  }
  .theme-switch-icon-toggle:focus-visible {
    box-shadow: 0 0 0 0.125em var(--theme-switch-primary);
  }
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 16px;
  color: var(--clr-text);
  background: var(--clr-background);
  font-family: var(--font-family);
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p,
a {
  font-size: 1rem;
}

a {
  text-decoration: none;
  color: inherit;
}

.sr-only {
  display: none;
}

#main,
#error,
#about,
#techstack,
#projects,
#resume,
#contact,
#loading {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

#get-in-touch {
  display: flex;
  align-items: center;
  justify-content: center;
}
#get-in-touch .icon {
  color: var(--clr-text);
  text-align: center;
  font-size: 1rem;
  font-weight: 200;
  line-height: 1;
  transition: scale 0.3s ease-in-out;
}
#get-in-touch .icon:hover {
  scale: 1.1;
}
#get-in-touch .email-link {
  text-transform: uppercase;
  margin-block: 2rem 2rem;
  transition: scale 0.3s ease-in-out;
}
#get-in-touch .email-link:hover {
  scale: 1.05;
}

body:has(#loading) {
  overflow: hidden;
}
body:has(#loading) #loading .alias-animate {
  color: var(--clr-text-secondary);
}

#about-list li,
#projects-list li {
  border-radius: 0.5rem;
  padding: 0.5rem 0;
  margin: 0.5rem 0;
  transition: all 0.3s ease-in-out;
}

#projects-list li:has(a:hover) {
  color: var(--clr-text);
  background-color: var(--clr-background-hover);
  padding: 0.5rem 1rem;
}
#projects-list li:has(a:hover) p:first-child {
  text-decoration: underline;
}

#nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--nav-background);
  box-shadow: var(--nav-box-shadow);
  z-index: 99;
}
#nav .nav-wrapper {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}
#nav .nav-wrapper .nav-link {
  color: var(--clr-text);
  text-align: center;
  font-size: 1rem;
  font-weight: 200;
  line-height: 1;
  text-transform: uppercase;
  padding: 1rem;
}

@media screen and (max-width: 768px) {
  #nav .nav-wrapper {
    gap: 0;
  }
  #nav .nav-wrapper .nav-link {
    padding: 1rem 0.5rem;
  }
}
.cookie-overlay {
  position: fixed;
  top: 1rem;
  background: var(--dialog-background);
  color: var(--clr-text);
  z-index: 9999;
  line-height: 1;
  border-radius: 1rem;
  border: none;
  box-shadow: var(--dialog-box-shadow);
}
.cookie-overlay .flex {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.cookie-overlay .flex.space-between {
  justify-content: space-between;
}
.cookie-overlay .flex.flex-end {
  justify-content: flex-end;
}
.cookie-overlay .cookie-content {
  width: 25rem;
}
.cookie-overlay .accept-cookies,
.cookie-overlay .reject-cookies {
  background: var(--clr-background);
  color: var(--clr-text-secondary);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
}
.cookie-overlay .accept-cookies {
  background: #25b6f4;
}
.cookie-overlay .reject-cookies {
  background: #dc3545;
}
.cookie-overlay a {
  text-decoration: underline;
}
.cookie-overlay .close-cookies {
  cursor: pointer;
}
